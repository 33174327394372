import { z } from "zod";


export const contactSchema = z.object({
  contactNo: z.number().readonly(),
  name: z.string().max(50),
  email: z.string().email().optional().nullable(),
  phoneNo: z.string().optional().nullable()
});

export type ContactType = z.infer<typeof contactSchema>;