import React, { useEffect, useState } from 'react'
import { useAxiosPrivate, useTranslate } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { Accordion, Button, Centralizer, ChangeDialog, Container, DataTable, Grid, Group, Select, TextField } from '../components';
import { ZodError, set } from 'zod';
import { UserType, userSchema } from '../types/User';
import { areIdentical } from '../utils';
import { Axios, AxiosError } from 'axios';
import { toast } from 'sonner';

const UserAdministration = () => {
  const api = useAxiosPrivate();
  const navigate = useNavigate();
  const { t, tZod } = useTranslate();

  const [changed, setChanged] = useState<number>(0) //Checks if form has changed
  const [usersList, setUsersList] = useState<UserType[]>([])
  const [user, setUser] = useState<UserType>()
  const [newUser, setNewUser] = useState<boolean>(false)
  const [originalUser, setOriginalUser] = useState<UserType | undefined>()
  const [validationErrors, setValidationErrors] = useState<ZodError<UserType> | null>(null)

  const getUsers = async () => {
    try {
      const response = await api.get({endpoint:'/user', mount:null})
      setUsersList(response.data as UserType[])
    } catch (e) {
      console.error(e)
      navigate('/login')
    }
  }

  useEffect(() => {
    getUsers()
  }, [])

  useEffect(() => {
    if (!areIdentical(user, originalUser)) setChanged(1)
  }, [user])

  const handleNewUser = async () => {
    setNewUser(true)
    setOriginalUser(undefined)
    setChanged(1)
    setUser({} as UserType)
  }

  const handleLoadForm = async (user: UserType) => {
    try {
      setValidationErrors(null)
      console.log('Load user: ', user)
      setNewUser(false)
      setUser(user)
      setOriginalUser(user)
      setChanged(0)
    } catch (e) {
      console.error(e)

      if (e instanceof AxiosError) {
        const error = e.response?.data?.message || e.response?.data || e.message
        toast.error(error)
      } else {
        toast.error('An error occurred loading user')
      }
    }
  }

  const handleSaveUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    console.log('Save user: ', user)
    if (!user) return
    try {
      setValidationErrors(null)
      const result = userSchema.safeParse(user)
      if (!result.success) {
        setValidationErrors(tZod(result.error))
        console.log(result.error)
        return false
      }
      if (newUser) {
        const response = await api.post({
          endpoint: '/user',
          data: { user },
          mount: {
            loadingText: t("saving"),
            onLoadText: t("saved"),
          }
        })
        console.log(response.data)
        setNewUser(false)
        setUser(undefined)
        setOriginalUser(response.data)
        setChanged(0)
        getUsers()

        toast.success('User created')
      } else {
        const response = await api.put({
          endpoint: `/user`,
          data: { user: user },
          mount: {
            loadingText: t("saving"),
            onLoadText: t("saved"),
          }
        })
        console.log(response.data)
        setUser(response.data)
        setOriginalUser(response.data)
        setChanged(0)
        getUsers()

        toast.success('User saved')
      }
    } catch (e) {
      console.error(e)

      if (e instanceof AxiosError) {
        const error = e.response?.data?.message || e.response?.data || e.message
        toast.error(error)
      } else {
        toast.error('An error occurred')
      }
    }
  }

  const handleDeleteUser = async (username?: string | null | undefined) => {
    try {
      if (!username) return
      const response = await api.delete({endpoint: `/user/${username}`, mount: {
        loadingText: t("deleting"),
        onLoadText: t("deleted"),
      }})
      console.log(response)
      setUser(undefined)
      setOriginalUser(undefined)
      setNewUser(false)
      setChanged(0)
      getUsers()

      toast.success('User deleted')
    } catch (e) {
      console.error(e)

      if (e instanceof AxiosError) {
        const error = e.response?.data?.message || e.response?.data || e.message
        toast.error(error)
      } else {
        toast.error('An error occurred')
      }
    }
  }

  return (
    <Centralizer style={{
      width: "100vw"
    }}>
      <Container style={{
        height: "40vh",
        maxHeight: "50vh",
        position: "static",
        width: "clamp(1000px, 80%, 80vw)"
      }}>
        {user ?
          <>
            <form onSubmit={handleSaveUser}>
              <Group label={" "}>
                <Grid>
                  <TextField
                    required
                    value={user.username || ''}
                    onChange={(e) => setUser((prev: any) => ({ ...user, email: e.target.value }))}
                    error={validationErrors?.errors.find(e => e.path[0] === "email")?.message}
                    readOnly={!newUser}
                    _type='email'
                  >{t("email")}
                  </TextField>
                  <TextField
                    value={user.fullName || ''}
                    onChange={(e) => setUser((prev: any) => ({ ...user, fullName: e.target.value }))}
                    error={validationErrors?.errors.find(e => e.path[0] === "fullName")?.message}
                  >{t("fullName")}
                  </TextField>
                  <TextField
                    value={user.phoneNo || ''}
                    onChange={(e) => setUser((prev: any) => ({ ...user, phoneNo: e.target.value }))}
                    error={validationErrors?.errors.find(e => e.path[0] === "phoneNo")?.message}
                    _type='tel'
                  >{t("phone")}
                  </TextField>
                </Grid>
                {user?.username && !newUser ?
                  <Button _type="button" className='schipt-btn primary' variant='delete' onClick={() => { handleDeleteUser(user.username); return false }}>Delete user</Button>
                  : <></>
                }
              </Group>
              <ChangeDialog show={changed >= 1 ? true : false}
                undo={async () => {
                  // If "Changed" carrier is a "new" carrier, then remove it.
                  if (originalUser?.username === "") {
                    setUsersList((prevState: UserType[]) => {
                      const i: number = prevState.findIndex((c: UserType) => c.username === "")
                      prevState.splice(i)
                      return prevState;
                    })
                    setUser({} as UserType)
                  }
                  else setUser(originalUser)
                  setTimeout(() => setChanged(0), 10)
                }}
              />
            </form>
          </>
          :
          <></>
        }


        <Accordion label={t("users")} defaultOpen={true}>
          <Container style={{ maxHeight: "25rem", overflowY: "scroll" }}>
            <DataTable
              data={usersList as UserType[]}
              style={{ maxHeight: "24rem" }}
              openCallback={async (i: number) => { handleLoadForm(usersList[i]) }}
            />
          </Container>
          <Button variant="add" className='schipt-btn primary' onClick={() => { handleNewUser() }}>New User</Button>
        </Accordion>
      </Container>
    </Centralizer>
  )
}

export default UserAdministration