import { z } from "zod";


export const itemlineSchema = z.object({
    itemNo: z.string().max(50),
    countryOfOrigin: z.string().max(2),
    hsTariffNumber: z.string().max(12),
    description: z.string().max(50),
    weight: z.number().min(0),
    price: z.number().min(0),
    category: z.string().max(50),
    orderedQuantity: z.number().min(1),
    pickedQuantity: z.number().min(0),
});

export type ItemlineType = z.infer<typeof itemlineSchema>;