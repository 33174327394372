import React, { FC, useState, useEffect, ReactNode } from "react"
import { useJSONState, useTranslate } from "../hooks";
import { Switch, TextField, Form, Container, Grid, Button } from "../components";

type objKeyProps = {
  name: string,
  type: "text" | "number" | "switch",
  required?: boolean,
  readOnly?: boolean,
  value?: boolean | number | string
}

type objType = {
  [key:string]: objKeyProps
}

type headOptionsType = {
  title?: string,
  okText?: string,
  cancelText?: string,
  hideCancelButton?: boolean,
  validation?: () => Promise<void>
}

type resultType = {
  [key:string]: boolean | number | string | undefined
}

type DialogProps = {
  children?: ReactNode;
  mainDialog?: boolean;
  obj?: objType;
};

let dialogCallback: (obj?: objType, headOptions?: headOptionsType) => void;
let resolvePromise: (result: resultType | null) => void;

const dialog = {
  open: async (obj: objType, headOptions?: headOptionsType) => {
    return new Promise<resultType | null>(resolve => {
      dialogCallback && dialogCallback(obj, headOptions)
      resolvePromise = resolve;
    })
  },
  close: () => dialogCallback && dialogCallback(undefined),
  _register: (callback: (obj?: objType, headOptions?: headOptionsType) => void) => dialogCallback = callback
};

const Dialog: FC<DialogProps> = ({ mainDialog, children }) => {
  const [dialogState, setDialogState] = useState<{ obj?: objType, headOptions?: headOptionsType}>({obj: undefined, headOptions:undefined});
  const [ json, setProp, setJson ] = useJSONState({})
  const [ errorMessage, setErrorMessage ] = useState("")
  const { t } = useTranslate()

  useEffect(() => {
    if(mainDialog) dialog._register((obj?: objType, headOptions?: headOptionsType) => {
      setErrorMessage("")
      setDialogState({ obj, headOptions })
      let json:resultType = {}
      obj && Object.keys(obj).map(key => {
        json[key] = obj[key].value ?? (obj[key].type === "switch" ? false : undefined)
      })
      setJson(json)
    });
  }, []);

  useEffect(() => {
    const dialogElement = document.querySelector(".schipt-dialog") as HTMLDialogElement;
  
    if (!!children || !!dialogState.obj) {
      dialogElement?.showModal(); // Ensures the dialog behaves as a modal
    } else {
      dialogElement?.close();
    }
  }, [children, dialogState.obj]);


  return (
    <dialog className="schipt-dialog">
      {
        dialogState.obj ? 
        <Container>
        <Form onSubmit={async () => {                
          try{
            if(dialogState.headOptions?.validation) await dialogState.headOptions.validation()

            resolvePromise(json)
            dialog.close()
            return Promise.resolve()
          }catch(e){
            setErrorMessage(e)
            return Promise.resolve()
          }                    
        }}>
          {!dialogState.headOptions?.title ? <></> : <h2>{dialogState.headOptions.title}</h2>}
          <>
            {Object.keys(dialogState.obj).map((key, i) => {
              if(!dialogState?.obj?.[key]) return <></>
              let o = dialogState.obj[key]

              if(o.type === "switch") return <Switch 
                label={o.name}
                checked={json[key]}
                onChange={() => {
                  setProp(!json[key], key)
                  setErrorMessage("")
                }}
                key={i}
              />

              return <TextField 
                required={o.required} 
                _type={o.type}
                readOnly={o.readOnly}
                value={json[key]}
                onChange={(e) => {
                  setProp(e.currentTarget.value, key)
                  setErrorMessage("")
                }}
                key={i}
              >{o.name}</TextField>
            })
            }
          </>
            <Grid>
              <Button _type="submit" variant="submit">{dialogState.headOptions?.okText ?? t("ok")}</Button>
              <Button _type="button" variant="undo" onClick={() => {
                resolvePromise(null)
                dialog.close()
              }}>{dialogState.headOptions?.cancelText ?? t("close")}</Button>
            </Grid>
            {errorMessage === "" ? <></> :
              <div style={{color: "red"}}>{errorMessage}</div>
            }
        </Form>
        </Container>
        :
        children
      } 
    </dialog>
  );
};


export default Dialog

export { dialog }