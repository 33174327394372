import { axiosPrivate } from "../api/axios";
import { AxiosResponse } from "axios"
import { useEffect, useRef } from "react";
import { toast } from "sonner";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const { auth } = useAuth();
  const abortControllersRef = useRef(new Set());

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      config => {
        config.headers = config.headers ?? {};
        if (!config.headers?.["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${auth?.accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    )
    const responseIntercept = axiosPrivate.interceptors.response.use(
      response => response,
      async (error) => {

        const prevRequest = error?.config;
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return axiosPrivate(prevRequest);
        }
        return Promise.reject(error);
      }
    )

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    }
  }, [auth, refresh])

  const __fetcher = async (method: "GET" | "POST" | "PUT" | "DELETE", 
    config: {
      endpoint: string,
      data?: {[key:string]: any},
      params?: {[key:string]: any},
      mount: null | {
        loadingText: string;
        onLoadText?: string | {text:string, type: "success" | "info"};
      }      
    }
  )  => {
    let toastId: number | string | undefined;
    let mount = config.mount

    try{        
      if(mount !== null){
        toastId = toast.loading(mount.loadingText)
      }

      let response;
      if(method === "GET") response = await axiosPrivate.get(config.endpoint, config.params)
      else if(method === "POST") response = await axiosPrivate.post(config.endpoint, config.data)
      else if(method === "PUT") response = await axiosPrivate.put(config.endpoint, config.data)
      else if(method === "DELETE") response = await axiosPrivate.delete(config.endpoint, config.params)

      if(mount && toastId !== undefined){
        if(mount.onLoadText){
          if(typeof mount.onLoadText === "string") toast.success(mount.onLoadText, { id: toastId });
          else {
            const { text, type } = mount.onLoadText
            toast[type](text, { id : toastId})
          }
        }
        else toast.dismiss(toastId)
      }

      return Promise.resolve(response as AxiosResponse<any, any>)
    }catch(e){
      let { status } = e.response
      if(status < 500){
        let errMsg = e.response?.data?.message || e.response?.data || e.message
        if(toastId) toast.error(errMsg, { id: toastId });
        else toast.error(errMsg)
      }
      else if(toastId !== undefined) toast.dismiss(toastId)
     
      return Promise.reject(e as AxiosResponse<any, any>)
    }    
  }

  const ret = {
    get: async function(config: {
      endpoint: string,
      params?: {[key:string]: string | number | boolean}
      mount: null | {
        loadingText: string;
        onLoadText?: string | {text:string, type: "success" | "info"};
      }      
    }){return __fetcher("GET", config)},

    post: async function(config: {
      endpoint: string,
      data?: {[key:string]: any} | {[key:string]: any}[]
      mount: null | {
        loadingText: string;
        onLoadText?: string | {text:string, type: "success" | "info"};
      }      
    }){return __fetcher("POST", config)},

    put: async function(config: {
      endpoint: string,
      data?: {[key:string]: any} | {[key:string]: any}[]
      mount: null | {
        loadingText: string;
        onLoadText?: string | {text:string, type: "success" | "info"};
      }      
    }){return __fetcher("PUT", config)},

    delete: async function(config: {
      endpoint: string,
      params?: {[key:string]: string | number | boolean}
      mount: null | {
        loadingText: string;
        onLoadText?: string | {text:string, type: "success" | "info"};
      }      
    }){return __fetcher("DELETE", config)},
  }


  return ret;
}

export default useAxiosPrivate