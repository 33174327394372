import React, { useEffect, useState } from 'react'
import { Accordion, Button, Centralizer, ChangeDialog, Container, DataTable, Grid, Group, Select, TextField } from '../components'
import { CompanyType, companySchema } from '../types/Company'
import { isRequired } from '../utils'
import { useAxiosPrivate, useTranslate } from '../hooks'
import { ZodError, set } from 'zod'
import { areIdentical } from '../utils'
import { AxiosError } from 'axios'
import { toast } from 'sonner'
import Priceplans from '../components/Priceplans/Priceplans'

const Subscription = () => {
  const api = useAxiosPrivate()
  const { t, tZod } = useTranslate()
  const [changed, setChanged] = useState<number>(0) //Checks if form has changed
  const [company, setCompany] = useState<CompanyType>()
  const [originalCompany, setOriginalCompany] = useState<CompanyType | undefined>()
  const [validationErrors, setValidationErrors] = useState<ZodError<CompanyType> | null>(null)
  const [error, setError] = useState<string | null>(null)

  const [carrierSuiteId, setCarrierSuiteId] = useState<string>("")
  const [carrierSuites, setCarrierSuites] = useState<any[]>([])
  const [activeCarriers, setActiveCarriers] = useState<any[]>([])
  const [invoices, setInvoices] = useState<any[]>([])

  const getCarrierSuites = async () => {
    try {
      const response = await api.get({
        endpoint: "/company/carrierSuites",
        mount: null
      })
      if (response.data) {
        setCarrierSuites(response.data.map((cs: any) => ({ value: cs.carrierSuiteId, name: cs.name })))
      }
    } catch (e) {
      console.error(e)
    }
  }

  const getActiveCarriers = async () => {
    try {
      const response = await api.get({
        endpoint: "/company/activeCarriers",
        mount: null
      })
      if (response.data)
        setActiveCarriers(response.data)
    } catch (e) {
      console.error(e)
    }
  }

  const getCompany = async () => {
    try {
      const response = await api.get({
        endpoint: '/company',
        mount: null
      })
      console.log(response.data)
      setCompany(response.data)
      setOriginalCompany(response.data)
      setChanged(0)
    } catch (e) {
      console.error(e)
      // navigate('/login')
    }
  }

  const getInvoices = async () => {
    try {
      const response = await api.get({
        endpoint: '/company/invoices',
        mount: null
      })
      console.log(response.data)

      if (response.data) {
        setInvoices(response.data)
      }

    } catch (e) {
      console.error(e)
    }
  }

  const getInvoiceFile = async (invoiceId: string) => {
    try {
      const response = await api.get({
        endpoint: `/company/invoice/${invoiceId}`,
        mount: {
          loadingText: t("fetchingFile")
        }
      })

      if (response.data) {
        console.log(response.data)
        downloadFile(response.data.base64Data, response.data.fileName);
      }
    } catch (e) {
      console.error(e)
    }
  }


  const downloadFile = (base64Data: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = `data:application/pdf;base64,${base64Data}`;
    link.download = fileName;
    link.click();
  }

  useEffect(() => {
    getCompany()
    getActiveCarriers()
    getCarrierSuites()
    getInvoices()
  }, [])

  useEffect(() => {
    if (!areIdentical(company, originalCompany)) setChanged(1)
  }, [company])

  const handleSave = async (e: any) => {
    e.preventDefault()
    try {
      setError(null)
      setValidationErrors(null)
      const result = companySchema.safeParse(company)
      if (!result.success) {
        setValidationErrors(tZod(result.error))
        console.log(result.error)
        return false
      }

      console.log('Saving company: ', company)
      const response = await api.put({
        endpoint: '/company',
        data: {company: company},
        mount: {
          loadingText: t("saving"),
          onLoadText: t("saved")
        }
      })
      console.log(response.data)
      setOriginalCompany(company)
      setChanged(0)
    } catch (e) {
      console.error(e)

      if (e instanceof AxiosError) {
        const error = e.response?.data?.message || e.response?.data || e.message
        toast.error(error)
      } else {
        toast.error('An error occurred')
      }
    }
  }

  const handlePriceplanChange = async (planId: string, extraUsers: number, extraCarrierSuites: number, extraStorageDays: number) => {

    setCompany((prev: any) => (
      {
        ...prev,
        paymentPlanId: planId,
        nmbExtraUsers: extraUsers,
        nmbExtraTrp: extraCarrierSuites,
        saveExtraDays: extraStorageDays
      })
    )

  }

  const handleAddCarrier = async (carrierSuiteId: string) => {
    try {
      if (activeCarriers.length + 1 > (company?.nmbExtraTrp || 0) + 3)
        throw new Error('You have reached the maximum number of active carriers for your subscription')

      const response = await api.post({
        endpoint: '/company/carrier',
        data: {carrierSuiteId},
        mount: null
      })
      console.log(response.data)

      getActiveCarriers()
      setCarrierSuiteId("")
    } catch (e) {
      console.error(e)

      if (e instanceof AxiosError) {
        const error = e.response?.data?.message || e.response?.data || e.message
        toast.error(error)
      } else {
        toast.error(e.message || 'An error occurred')
      }
    }
  }

  const handleRemoveCarrier = async (carrierSuiteId: string) => {
    try {
      const response = await api.delete({
        endpoint: `/company/carrier/${carrierSuiteId}`,
        mount: {
          loadingText: t("deleting")
        }
      })
      console.log(response.data)

      getActiveCarriers()
      setCarrierSuiteId("")
    } catch (e) {
      console.error(e)

      if (e instanceof AxiosError) {
        const error = e.response?.data?.message || e.response?.data || e.message
        toast.error(error)
      } else {
        toast.error('An error occurred')
      }
    }
  }

  function isDue(dateString: string) {
    const date = new Date(dateString);
    date.setHours(0, 0, 0, 0);
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return date < now;
  }

  return <Container className='overflow-y-scroll overflow-x-hidden pb-32'>
    <form onSubmit={async (e) => { handleSave(e) }}>
      <Centralizer>
        <Container>
          {company && (
            <>
              {error && <p>{error}</p>}
              <Accordion label={t("account.subscription")} defaultOpen={true}>
                <Container style={{maxWidth:"1000px"}}>
                    <Group label={t('address')}>
                      <Grid>
                        <TextField
                          className='w-full'
                          required={isRequired('name', companySchema) ? true : false}
                          value={company.name || ''}
                          onChange={(e) => setCompany((prev: any) => ({ ...prev, name: e.target.value }))}
                          error={validationErrors?.errors.find(e => e.path[0] === "name")?.message}                          
                        >{t("companyname")}
                        </TextField>
                        <TextField
                          className='w-full'
                          required={isRequired('street1', companySchema) ? true : false}
                          value={company.street1 || ''}
                          onChange={(e) => setCompany((prev: any) => ({ ...prev, street1: e.target.value }))}
                          error={validationErrors?.errors.find(e => e.path[0] === "street1")?.message}
                        >{t("street1")}
                        </TextField>
                        <TextField
                          className='w-full'
                          required={isRequired('street2', companySchema) ? true : false}
                          value={company.street2 || ''}
                          onChange={(e) => setCompany((prev: any) => ({ ...prev, street2: e.target.value }))}
                          error={validationErrors?.errors.find(e => e.path[0] === "street2")?.message}
                        >{t("street2")}
                        </TextField>
                        <TextField
                          className='w-full'
                          required={isRequired('street3', companySchema) ? true : false}
                          value={company.street3 || ''}
                          onChange={(e) => setCompany((prev: any) => ({ ...prev, street3: e.target.value }))}
                          error={validationErrors?.errors.find(e => e.path[0] === "street3")?.message}
                        >{t("street3")}
                        </TextField>
                        <TextField
                          className='w-full md:w-24'
                          required={isRequired('zipCode', companySchema) ? true : false}
                          value={company.zipCode || ''}
                          onChange={(e) => setCompany((prev: any) => ({ ...prev, zipCode: e.target.value }))}
                          error={validationErrors?.errors.find(e => e.path[0] === "zipCode")?.message}
                        >{t("zipCode")}
                        </TextField>
                        <TextField
                          className='w-full'
                          required={isRequired('city', companySchema) ? true : false}
                          value={company.city || ''}
                          onChange={(e) => setCompany((prev: any) => ({ ...prev, city: e.target.value }))}
                          error={validationErrors?.errors.find(e => e.path[0] === "city")?.message}
                        >{t("city")}
                        </TextField>
                      </Grid>
                    </Group>
                    <Group label={t('account.contact')}>
                      <Grid>
                        <TextField
                          className='w-full'
                          required={isRequired('contactName', companySchema) ? true : false}
                          value={company.contactName || ''}
                          onChange={(e) => setCompany((prev: any) => ({ ...prev, contactName: e.target.value }))}
                          error={validationErrors?.errors.find(e => e.path[0] === "contactName")?.message}
                        >{t("contactName")}
                        </TextField>
                        <TextField
                          className='w-full'
                          required={isRequired('contactPhone', companySchema) ? true : false}
                          value={company.contactPhone || ''}
                          onChange={(e) => setCompany((prev: any) => ({ ...prev, contactPhone: e.target.value }))}
                          error={validationErrors?.errors.find(e => e.path[0] === "contactPhone")?.message}
                          _type='tel'
                        >{t("contactPhone")}
                        </TextField>
                        <TextField
                          className='w-full md:min-w-80'
                          required={isRequired('contactEmail', companySchema) ? true : false}
                          value={company.contactEmail || ''}
                          onChange={(e) => setCompany((prev: any) => ({ ...prev, contactEmail: e.target.value }))}
                          error={validationErrors?.errors.find(e => e.path[0] === "contactEmail")?.message}
                          _type='email'
                        >{t("contactEmail")}
                        </TextField>
                      </Grid>
                    </Group>
                    <Group label={t('account.contact_finance')}>
                      <Grid>
                        <TextField
                          className='w-full'
                          required={isRequired('financeContactName', companySchema) ? true : false}
                          value={company.financeContactName || ''}
                          onChange={(e) => setCompany((prev: any) => ({ ...prev, financeContactName: e.target.value }))}
                          error={validationErrors?.errors.find(e => e.path[0] === "financeContactName")?.message}
                        >{t("contactName")}
                        </TextField>
                        <TextField
                          className='w-full'
                          required={isRequired('financeContactPhone', companySchema) ? true : false}
                          value={company.financeContactPhone || ''}
                          onChange={(e) => setCompany((prev: any) => ({ ...prev, financeContactPhone: e.target.value }))}
                          error={validationErrors?.errors.find(e => e.path[0] === "financeContactPhone")?.message}
                          _type='tel'
                        >{t("contactPhone")}
                        </TextField>
                        <TextField
                          className='w-full md:min-w-80'
                          required={isRequired('financeContactEmail', companySchema) ? true : false}
                          value={company.financeContactEmail || ''}
                          onChange={(e) => setCompany((prev: any) => ({ ...prev, financeContactEmail: e.target.value }))}
                          error={validationErrors?.errors.find(e => e.path[0] === "financeContactEmail")?.message}
                          _type='email'
                        >{t("contactEmail")}
                        </TextField>
                      </Grid>
                    </Group>
                    <Group label={t('account.invoiceInfo')}>
                      <Grid>
                      <TextField
                        className='w-full md:min-w-80'
                        required={isRequired('emailInvoiceTo', companySchema) ? true : false}
                        value={company.emailInvoiceTo || ''}
                        onChange={(e) => setCompany((prev: any) => ({ ...prev, emailInvoiceTo: e.target.value }))}
                        error={validationErrors?.errors.find(e => e.path[0] === "emailInvoiceTo")?.message}
                        _type='email'
                      >{t("account.emailInvoiceTo")}
                      </TextField>
                      <TextField
                        className='w-full'
                        required={isRequired('orgNo', companySchema) ? true : false}
                        value={company.orgNo || ''}
                        onChange={(e) => setCompany((prev: any) => ({ ...prev, orgNo: e.target.value }))}
                        error={validationErrors?.errors.find(e => e.path[0] === "orgNo")?.message}
                      >{t("orgNo")}
                      </TextField>
                      <TextField
                        className='w-full'
                        required={isRequired('vatNo', companySchema) ? true : false}
                        value={company.vatNo || ''}
                        onChange={(e) => setCompany((prev: any) => ({ ...prev, vatNo: e.target.value }))}
                        error={validationErrors?.errors.find(e => e.path[0] === "vatNo")?.message}
                      >{t("vatNo")}
                      </TextField>
                      </Grid>
                    </Group>
                </Container>
              </Accordion>
              <Accordion label={t("account.priceplan")} defaultOpen={false}>
                <Grid style={{ alignItems: 'start' }}>
                  <Priceplans
                    hideHeader={true}
                    planId={company.paymentPlanId as string}
                    extraUsers={company.nmbExtraUsers as number}
                    extraCarrierSuites={company.nmbExtraTrp as number}
                    extraStorageDays={company.saveExtraDays as number}
                    currentNmbUsers={company.usersCount as number}
                    currentNmbCarrierSuites={activeCarriers.length as number}
                    onPlanChange={handlePriceplanChange} />
                </Grid>
              </Accordion>
              <Accordion label={t("account.activeCarriers")} defaultOpen={false}>
              <Group label={t('account.activeCarriers')}>
                <Grid>
                    <Select className ='w-full dark:bg-schipt-darkest font-montserrat text-sm p-1'
                      // label="CarrierSuite"
                      value={carrierSuiteId}
                      data={carrierSuites}
                      onChange={async (e) => setCarrierSuiteId(e.target.value)}
                      blankOption={true}                  
                    />
                    <Button _type="button" variant="add" onClick={() => handleAddCarrier(carrierSuiteId)}>{t("addCarrier")}</Button>
                    <DataTable data={activeCarriers}
                      deleteCallback={(i) => handleRemoveCarrier(activeCarriers[i].id)}
                      hideColumns={["id"]}
                      hideHeaders={true}
                    />
                    </Grid>
                  </Group>            
              </Accordion>
              <Accordion label={t("account.invoices")} defaultOpen={false}>
                <Grid>
                  <DataTable
                    // className="w-full"
                    style={{ width: '100%' }}
                    data={invoices.map((invoice) => (
                      {
                        invoiceNo: invoice.invoiceNo,
                        invoiceDate: invoice.invoiceDate,
                        dueDate: <span>{invoice.dueDate} {isDue(invoice.dueDate) ? '❗❗❗' : ''}</span>,
                        invoiceType: invoice.invoiceType,
                        invoiceStatus: <span>{invoice.invoiceStatus == 30 ? '✅ Paid' : '❗Not paid'}</span>,
                        invoiceTotalInclVAT: invoice.invoiceTotalInclVAT,
                        currencyCode: invoice.currencyCode,
                        sentTo: invoice.sentTo,
                        actions: invoice.hasFile ? (
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => getInvoiceFile(invoice.invoiceNo)}>⬇️ Download</span>
                        ) : (
                          <></>
                        )
                      }
                    ))}

                    translatePath='invoices'
                  // openCallback={async (i: number) => { }}
                  // deleteCallback={async (i: number) => { }}
                  ></DataTable>
                </Grid>
              </Accordion>

              <ChangeDialog show={changed >= 1 ? true : false}
                undo={async () => {
                  setCompany(originalCompany)
                  setError(null)
                  setValidationErrors(null)
                  setTimeout(() => setChanged(0), 10)
                }}
              />
            </>
          )}
        </Container>
      </Centralizer >
    </form>
  </Container>
}

export default Subscription