import { z } from "zod";

export const documentsSchema = z.object({
    docGUID: z.string().nullish().optional(),
    documentName: z.string().nullish().optional(),
    printType: z.string().nullish().optional(),
    printStatus: z.number().refine(val => [10, 20, 22, 25, 30, 90].includes(val), {
        message: "Invalid printStatus value",
    }),
});

export type DocumentType = z.infer<typeof documentsSchema>;