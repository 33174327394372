import { useContext } from "react";
import { WebSocketContext } from "../context";

// Custom hook to use the WebSocket context
const useWebSocket = () => {
  const context = useContext(WebSocketContext);

  if (!context) {
    throw new Error("useWebSocketClient must be used within a WebSocketProvider");
  }

  const { send, sendJson, readyState } = context;

  return {
    send,
    sendJson,
    readyState,
  };
};

export default useWebSocket;