import React, { useEffect, useRef, useState } from 'react'
import PlanCard from './PlanCard'
import { set } from 'date-fns'
import { AxiosError } from 'axios';
import { useAxiosPrivate, useTranslate } from '../../hooks';
import { toast } from 'sonner';
import { Range } from '../../components';

const Priceplans = ({
    planId,
    extraUsers,
    extraCarrierSuites,
    extraStorageDays,
    hideHeader = false,
    currentNmbUsers,
    currentNmbCarrierSuites,
    currentNmbStorageDays,
    onPlanChange
}: {
    planId?: string
    extraUsers?: number
    extraCarrierSuites?: number
    extraStorageDays?: number
    hideHeader?: boolean
    currentNmbUsers?: number
    currentNmbCarrierSuites?: number
    currentNmbStorageDays?: number
    onPlanChange?: (planId: string, extraUsers: number, extraCarrierSuites: number, extraStorageDays: number) => void
}) => {
    const api = useAxiosPrivate()
    const { t } = useTranslate()

    const [activePlanId, setActivePlanId] = useState<string | undefined>('')
    const [paymentPlans, setPaymentPlans] = useState<any>([])
    const [nmbExtraUsers, setNmbExtraUsers] = useState(extraUsers || 0)
    const [nmbExtraCarrierSuites, setNmbExtraCarrierSuites] = useState(extraCarrierSuites || 0)
    const [nmbExtraStorageDays, setNmbExtraStorageDays] = useState(extraStorageDays || 0)
    const [extraStorageCost, setExtraStorageCost] = useState(0)
    
    const [nmbUsers, setNmbUsers] = useState<number>(1 + (nmbExtraUsers || 0))
    const [nmbCarrierSuites, setNmbCarrierSuites] = useState<number>(3 + (nmbExtraCarrierSuites || 0))

    // const [currentNmbUsers, setCurrentNmbUsers] = useState<number>(0)
    // const [currentNmbCarrierSuites, setCurrentNmbCarrierSuites] = useState<number>(0)


    useEffect(() => {
        fetchPaymentPlans().then((data) => {
            console.log(data)
            if (data instanceof AxiosError) console.error(data)
            if (data instanceof Array)
                setPaymentPlans(data)
        })

        // fetchCompanyInfo().then((data) => {
        //     console.log(data)
        //     if (data instanceof AxiosError) console.error(data)
        //     if (data instanceof Object) {
        //         setCurrentNmbUsers(data.usersCount)
        //         setCurrentNmbCarrierSuites(data.carrierSuitesCount)
        //         setActivePlanId(data.paymentPlanId)
        //         setNmbExtraUsers(data.nmbExtraUsers)
        //         setNmbCarrierSuites(data.nmbExtraTrp)
        //     }
        // })
    }, [])

    useEffect(() => {
        console.log('planId:', planId)
        if (planId) {
            setActivePlanId(planId)
        }
    }, [planId])

    useEffect(() => {
        setNmbExtraUsers(extraUsers || 0)
    }, [extraUsers])

    useEffect(() => {
        setNmbExtraCarrierSuites(extraCarrierSuites || 0)
    }, [extraCarrierSuites])


    useEffect(() => {
        if (paymentPlans && activePlanId)
            calculateExtraStorageCost(nmbExtraStorageDays)
    }, [paymentPlans, activePlanId, nmbExtraStorageDays])

    useEffect(() => {
        setNmbUsers(1 + (nmbExtraUsers || 0))
        if (onPlanChange && activePlanId)
            onPlanChange(activePlanId, nmbExtraUsers, nmbExtraCarrierSuites, nmbExtraStorageDays)
    }, [nmbExtraUsers])

    useEffect(() => {
        setNmbCarrierSuites(3 + (nmbExtraCarrierSuites || 0))
        if (onPlanChange && activePlanId)
            onPlanChange(activePlanId, nmbExtraUsers, nmbExtraCarrierSuites, nmbExtraStorageDays)
    }, [nmbExtraCarrierSuites])

    useEffect(() => {
        if (onPlanChange && activePlanId)
            onPlanChange(activePlanId, nmbExtraUsers, nmbExtraCarrierSuites, nmbExtraStorageDays)
    }, [nmbExtraStorageDays])

    const fetchPaymentPlans = async () => {
        const response = await api.get({
            endpoint: '/register/paymentplans',
            mount: null
        })
        const data = await response.data
        return data
    }

    // const fetchCompanyInfo = async () => {
    //     const response = await api.get('/company')
    //     const data = await response.data
    //     return data
    // }

    const calculatePrice = (nmbShipments: number) => {
        const plan = paymentPlans.find((plan: any) => plan.maxValue === nmbShipments)
        const basePrice = plan.planPrice
        const priceExtraUsers = nmbExtraUsers * plan.extraUserPrice
        const priceExtraCarrierSuites = nmbExtraCarrierSuites * Math.round((basePrice * (plan.extraTrpPrice / 100)))

        // console.log(plan, basePrice, priceExtraUsers, priceExtraCarrierSuites)
        return basePrice + priceExtraUsers + priceExtraCarrierSuites
    }

    const handleChangePlan = (planId: string) => {
        if (planId === activePlanId) return
        setActivePlanId(planId)
        if (onPlanChange)
            onPlanChange(planId, nmbExtraUsers, nmbExtraCarrierSuites, nmbExtraStorageDays)
    }

    const handleChangeNmbExtraUsers = (e: any) => {
        const nmbExtraUsers: number = parseInt(e.target.value)

        if (nmbExtraUsers < ((currentNmbUsers || 0) - 1)) {
            toast.info('You can not have more users than your subscription allows. Please remove some users before changing the subscription.')
            return
        }

        setNmbExtraUsers(nmbExtraUsers)
    }

    const handleChangeNmbExtraCarrierSuites = (e: any) => {
        const nmbExtraCarrierSuites: number = parseInt(e.target.value)

        if (nmbExtraCarrierSuites < ((currentNmbCarrierSuites || 0) - 3)) {
            toast.info('You can not have more active carriers than your subscription allows. Please remove some carriers before changing the subscription.')
            return
        }

        setNmbExtraCarrierSuites(nmbExtraCarrierSuites)
    }

    const handleChangeNmbExtraStorageDays = (e: any) => {

        const nmbExtraStorageDays: number = parseInt(e.target.value)
        setNmbExtraStorageDays(nmbExtraStorageDays)

        calculateExtraStorageCost(nmbExtraStorageDays)
        
    }

    const calculateExtraStorageCost = (nmbExtraStorageDays: number) => {
        const plan = paymentPlans.find((plan: any) => plan.planId === activePlanId)
        const price = plan?.extraStoragePricePerDay * nmbExtraStorageDays //Justera detta till månader istället för dagar
        setExtraStorageCost(price)
    }

    return (
        <section className="">
            <div className='py-2 px-4 max-w-screen-lg lg:py-6 lg:px-6'>
                {!hideHeader && (
                <div className="max-w-screen-md mb-8 md:mb-8">
                    <h2 className="mb-4 text-3xl tracking-tight font-extrabold">{t('ChoosePaymentPlan')}</h2>
                </div>
                )}
                <div className="flex flex-col md:flex-row text-sm">
                    <div className={`space-y-2 md:w-4/12 md:mr-4`}>
                        {paymentPlans && paymentPlans.map((plan: any, index: number) => {
                            return <PlanCard
                                key={index}
                                planId={plan.planId}
                                active={plan.planId === activePlanId}
                                title={'Plan ' + plan.planId}
                                description={''}
                                price={calculatePrice(plan.maxValue)}
                                currencyCode={plan.currencyCode}
                                features={[`${t('UpTo')} ${plan.maxValue} ${t('DeliveryUnits')}`]}
                                onClick={handleChangePlan}
                                interval={`${t('PerMonth')}`}
                                interval2={`${t('PerYear')}`}
                            />
                        }
                        )}
                        {(!paymentPlans || paymentPlans.length === 0) && <div style={{ fontSize: '2rem', textWrap: 'nowrap' }}>No valid paymenplans found, please contact support...</div>}
                        <p className='text-xs italic mt-2'>{t('ExtraInfo')}</p>
                    </div>
                    <div className='mt-12 md:mt-0 md:w-8/12'>
                        <div className='mb-4'>
                            <p className='font-montserrat text-lg'>{t('AllPlansContains')}</p>
                        </div>
                        <p className='font-bold'>{t('PaymentPlanMoreHeading')}</p>
                        <p className='font-light'>{t('PaymentPlanMoreInfo')}</p>

                        <Range min={0} max={10} step={1} label={`${t('ExtraUsers')}`} value={nmbExtraUsers} onChange={handleChangeNmbExtraUsers}></Range>
                            
                        <Range min={0} max={10} step={1} label={`${t('ExtraCarriers')}`} value={nmbExtraCarrierSuites} onChange={handleChangeNmbExtraCarrierSuites}></Range>
                        <br />  
                        <p className='font-bold'>{t('Storage')}</p>                  
                        <p>{t('StorageInfo')}</p>
                        <div className='flex items-center gap-5 mt-2'>
                            <label htmlFor="extraUsers">{t('ExtraStorageMonths')}</label>
                            <input
                                value={nmbExtraStorageDays}
                                onChange={handleChangeNmbExtraStorageDays}
                                type="number"
                                min={0}
                                max={36} // 3 years
                                className="text-sm px-2 py-1 mb-2 text-gray-900 bg-white border border-gray-300 rounded-lg shadow-sm dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-primary-500 focus:ring-primary-500 focus:ring-2 focus:ring-opacity-50"
                            />
                            <span>{t('AdditionalCostFor')} {nmbExtraStorageDays || 0} {t('AdditionalCostFor2')}<span style={{fontWeight: 'bold'}}>{extraStorageCost || 0}</span> {paymentPlans.find((plan: any) => plan.planId === activePlanId)?.currencyCode}{t('PerYear')}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Priceplans