import React, { FC, useId, useState, useEffect } from "react"

type option = {
  name: string,
  onClick?: () => void,
  options?: option[],
}

type Props = {
  options: option[],
  openOnLeftClick?: boolean,
  openOnRightClick?: boolean,
  children?: any
}

const Popover: FC<Props> = ({ options, openOnLeftClick, openOnRightClick, children }) => {
  const [isShown, setIsShown] = useState(false)
  const id = useId()
  openOnLeftClick = openOnLeftClick ?? true

  const popoverDefocus = (e: MouseEvent) => {
    const popoverElement = document.getElementById(id);
    const clickedElement = e.target

    if (!clickedElement || !popoverElement) return
    else if (popoverElement?.classList.contains("hidden")) return
    else if (!popoverElement.contains(clickedElement as Node)) setIsShown(false)
  }

  useEffect(() => {
    document.addEventListener("click", popoverDefocus)
    return function removePopoverDefocus() {
      document.removeEventListener("click", popoverDefocus)
    }
  }, [])

  const getOptions = (options: option[]) => {
    return options.map((o, i) => {
      return <li key={i}>
        <button type="button" className='z-20 schipt-btn secondary' onClick={() => {
          setIsShown(false)
          o.onClick?.()
        }} >{o.name}</button>
      </li>
    })
  }

  return <div id={id} className='testing relative w-fit'
    onClick={() => setIsShown(i => !i)}
    onContextMenu={(e) => {
      if (!openOnRightClick) return
      e.preventDefault()
      e.stopPropagation()
      setIsShown(i => !i)
    }}
  >
    {children}
    <div className={`popover-menu ${isShown ? "" : "hidden"} absolute w-m-[120px] right-0 top-[20px] z-10 p-2 bg-schipt-white dark:bg-schipt-darkest border shadow-md`}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <ul className='flex flex-col'>
        {getOptions(options)}
      </ul>
    </div>
  </div>

}

export default Popover