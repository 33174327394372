import React, { FC, useState } from "react"
import "../../assets/style/Form.css"

type Props = {
    children?: React.ReactElement | React.ReactElement[] | null,
    onSubmit: (e:React.FormEvent<HTMLFormElement>) => Promise<void>,
}

const Form: FC<Props> =  ({onSubmit, children}) => { 
  const [ loading, setLoading ] = useState(false)

    return <form 
      onSubmit={async (e) => {
        e.preventDefault()
        e.stopPropagation()
        setLoading(true)
        await onSubmit(e)
        setLoading(false)
      }}
      className={`schipt-form ${loading ? "submitting" : ""}`}
    >
      {children}
    </form>
}

export default Form