import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import "../assets/style/Carriers.css"
import { useLogout, useTranslate, useJSONState } from '../hooks';
import { TextField, Grid, DataTable
  , Container, Group, Accordion, Centralizer, Button, Select, Switch, ChangeDialog, ConfirmDialog} from '../components';
import { logger, deepCopyOf, areIdentical } from '../utils';
// import { carrierSuites } from '../utils/Variables/carrierSuites'
import { WriteToDeleteDialog } from "../components/Feedback"
import { AxiosError } from 'axios';
import { toast } from 'sonner';

export default function Carriers() {
  const api = useAxiosPrivate();
  const logout = useLogout();
  const [ carrierSuites, setCarrierSuites ] = useState<any[]>([])
  const [ carriersList, setCarriersList ] = useState<any[]>([])
  const [ originalCarrier, setOriginalCarrier ] = useState<any>({})
  const [ carrier, setCProp, setCarrier ] = useJSONState({})
  const [ carrierIdToDelete, setCarrierIdToDelete ] = useState("");

  const [ changed, setChanged] = useState<number>(0) //Checks if form has changed
  const { t } = useTranslate();
  const navigate = useNavigate()
  const { carrierId } = useParams();
  const [ dialog, setDialog ] = useState<any>(null);
  const location = useLocation();
  const [forceEffect, setForceEffect] = useState(false);


  const getCarrierSuites = async () => {
    const response = await api.get({endpoint: "/carrierSuites", mount: null})
    if (response.data)
      setCarrierSuites(response.data)
  }

  const getCarrierList = async () => {
      try{
        setCarriersList([])
        const response = await api.get({endpoint: "/carriers", params: {ts: new Date().getTime()}, mount:null})
        if (response.data.length > 0) {
          setCarriersList(response.data)
        }          
        else {
          setCarriersList([])
        } 
      }catch(e){
        console.error(e)
        logout()
      }    
  }
  
  useEffect(() => {
    getCarrierSuites()
    getCarrierList()
  }, [])

  useEffect(() => {  
    if (carrierId === undefined){
      if (carriersList[carriersList.length - 1]?.name !== "") return
      setCarrier({})
      setOriginalCarrier({})
      return
    }
    let isMounted = true;
    const controller = new AbortController();
    const getCarrier = async () => {
      try{
        const response = await api.get({endpoint: `carriers/${carrierId}`, mount:null})        
        if (isMounted){
          const tryParse = (d:any) => {
            try{
              return JSON.parse(d)
            }catch(e){
              return {}
            }              
          }
          setCarrier(response.data)
          setOriginalCarrier(response.data)
          setChanged(0)
        }          
      }catch(e){
        console.error(e)
        logout()
      }
    }

    getCarrier()

    return () => {
      isMounted = false;
      controller.abort()
    }
  }, [carrierId, forceEffect])

  useEffect(() => {
    areIdentical(carrier, originalCarrier) ? setChanged(0) : setChanged(1)
  }, [carrier])

  const navigateWithForceEffect = (path:string) => {
    setForceEffect(pre => !pre);
    navigate(path);
  }

  const SaveCarrier = async (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    let requestBody = deepCopyOf(carrier)
    if (!requestBody.carrierId || requestBody.carrierId == "") requestBody.carrierId = "new"        
    try{
      await api.put({
        endpoint: `/carriers/${requestBody.carrierId}`,
        data: requestBody,
        mount: {loadingText: t("saving")}
      })
      setOriginalCarrier(carrier)
      setChanged(0)
      getCarrierList()
      if (originalCarrier.carrierId === "") navigate(`/carriers/${carrier.carrierId}`, {replace: true})
      // setTimeout(() => setChanged(0), 10)
    }catch(e){
      console.error(e)
    }       
  }

  const deleteCarrier = async () => {
    try{
      await api.delete({
        endpoint: `/carriers/${carrierIdToDelete}`,
        mount: {loadingText: t("deleting")}
      })
      setCarrierIdToDelete("")
      setCarrier({});
      setOriginalCarrier({});
      setChanged(0)
      getCarrierList()

      toast.success("Carrier deleted")
    }catch(e){
      console.error(e)
      
      if (e instanceof AxiosError) {
          const error = e.response?.data?.message || e.response?.data || e.message
          toast.error(error)
      } else {
          toast.error('An error occurred')
      }
    }
  }

  return ( 
    <div className='schipt-carriers'>
      {dialog && <ConfirmDialog options={dialog} />}
      {carrierIdToDelete ?
        <WriteToDeleteDialog 
          title={t("dialogs.sureDeleteCarrier")} 
          textToWrite={carrier.name} 
          message={t("dialogs.repeatTextToDelete") + carrier.name}
          onCancel={() => setCarrierIdToDelete("")}
          onOk={() => { deleteCarrier() }}
        />
      : <></>
      }      
      <Centralizer style={{width:"100vw"}}>
        <Container style={{
          height:"40vh", 
          maxHeight:"50vh",
          position:"static",
          width: "clamp(1000px, 80%, 80vw)"
        }}>
          { carrier && Object.keys(carrier).length > 0 ?
            <form onSubmit={SaveCarrier}>
              <Group label={carrier.name}>
                <Grid style={{ marginTop:"20px"}}>
                  <Select 
                    label={t("CarrierSuite")}
                    value={carrier.carrierSuiteId} 
                    data={carrierSuites}
                    onChange={async (e) => { setCProp(e.target.value, "carrierSuiteId"); } }
                    required={true}
                    blankOption={true}
                    style={{ width: "15rem"}}
                  />
                  <TextField 
                    required
                    value={carrier.name} 
                    onChange={(v) => setCProp(v.target.value, "name")}
                  >{t("name")}</TextField> 
                  {
                    carrier.carrierSuiteId && carrierSuites.find((c:any) => c.value === carrier.carrierSuiteId)?.neededFields?.map((field: any, i:number) => {
                      return <TextField 
                          value={carrier[field.field]} 
                          _type={((/secret|password/i).test(field.field.toLowerCase())) ? "password" : "text"}
                          required={field.required} 
                          key={i} 
                          onChange={(v) => setCProp(v.target.value, field.field)}
                        >{field.name}</TextField>
                    })
                  }
                </Grid>
                                
                <Group label={t("documents")}>
                  <Grid>
                    {
                      carrier.documents && carrier.documents.map((d: any, i:number) => {
                        return <Switch 
                          key={i} 
                          label={t(d.document)}
                          checked={d.checked}
                          onChange={(v) => {setCProp(v.target.checked, "documents", i, "checked")}} 
                        />
                      })
                    }
                  </Grid>  
                </Group>
                                                      
                {originalCarrier?.carrierId !== "" ?
                  <Button variant='delete' onClick={() => {setCarrierIdToDelete(originalCarrier?.carrierId)}}>{t("deleteCarrier")}</Button>
                  : <></>
                }                
              </Group>
              <ChangeDialog show={changed == 1 ? true : false} 
                undo={async () => {
                  // If "Changed" carrier is a "new" carrier, then remove it.
                  if(originalCarrier?.carrierId === ""){
                    setCarriersList((prevState:any[]) => {
                      const i:number = prevState.findIndex((c:any) => c.name === "")
                      prevState.splice(i)
                      return prevState;
                    })
                    setCarrier({})
                  }
                  else setCarrier(originalCarrier)
                  setTimeout(() => setChanged(0), 10)        
                }}
                
              /> 
            </form>
            :
            <></>            
          }          
          <Accordion label={t("carriers")} defaultOpen={true}>
            <Container style={{maxHeight: "25rem", overflowY:"scroll"}}>
              <DataTable 
                data={carriersList} 
                style={{maxHeight:"34rem"}} 
                hideColumns={["carrierId"]}
                openCallback={async (i:number) => {navigate(`/carriers/${carriersList[i].carrierId}`, {replace: true})}}
              />
            </Container>
            <Button variant="add" onClick={() => {
              setCarrier({ carrierSuiteId: "", carrierId: "" })
              setOriginalCarrier({ carrierSuiteId: "", carrierId: "" })
            }}>{t("newCarrier")}</Button>
          </Accordion>
        </Container>        
      </Centralizer> 
    </div>
  ); 
}