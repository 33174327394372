import React, { createContext, useState, ReactElement, FC, useEffect } from "react";
import { useAuth } from "../hooks";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { toast } from "sonner";

type WebSocketContextType = {
  send?: (message: string) => void;
  sendJson?: (message: object) => void;
  readyState: ReadyState
} | undefined;

type Props = {
  children: ReactElement
}

const WebSocketContext = createContext<WebSocketContextType>(undefined);

export const WebSocketProvider: FC<Props> = ({ children }) => {
  const { auth } = useAuth();

  const [url, setUrl] = useState<string | null>(
    auth?.webSocketToken ? `${process.env.REACT_APP_BASE_WEBSOCKET_URL}?token=${auth.webSocketToken}` : null
  );

  useEffect(() => {
    if (auth?.webSocketToken) {
      setUrl(`${process.env.REACT_APP_BASE_WEBSOCKET_URL}?token=${auth.webSocketToken}`);
    }
  }, [auth]);

  // Close WebSocket connection on component unmount by setting URL to null
  useEffect(() => {
    return () => {
      setUrl(null);
    };
  }, []);

  const { sendMessage, sendJsonMessage, readyState } = useWebSocket(url, {
    onOpen: () => console.log('WebSocket connection established.', url, auth),
    onMessage: (event) => {
      try {
        const data = JSON.parse(event.data);
        console.log({data})
        if (data.type === 'errorMessage' && data.content)
          toast.error(data.content);
        // else if (data.type === 'ConsoleEvent') {
        //   console.log(data.message);
        // }
      } catch (e) {
        console.error(e);
      }
    },
    onClose: () => {
      console.log('WebSocket connection closed.');
    },
    onError: (event) => {
      console.info('WebSocket error:', event);
    },
    shouldReconnect: () => true,
    // Connect only if URL is set
    filter: () => url !== null,
  });

  const ws = {
    send: sendMessage,
    sendJson: sendJsonMessage,
    readyState,
  };

  return (
    <WebSocketContext.Provider value={ws}>
      {children}
    </WebSocketContext.Provider>
  );
}

export default WebSocketContext;